<template>
  <div class="composant-parametre-multiselect">
    <div class="label">
      {{ header }}
    </div>
    <div class="zone-input">
      <multiselect
        :value="value"
        label="label"
        track-by="value"
        :options="options"
        @input="
          (option) => {
            updateParams(paramRef, option);
          }
        "
        :showLabels="false"
        :searchable="false"
        :allow-empty="false"
      ></multiselect>
      <input
        :value="inputValue"
        v-if="addInput"
        type="number"
        min="0"
        class="input-filtre"
        @change="
          (e) => {
            updateParams(inputParamRef, e.target.value);
          }
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "composant-parametre-multiselect",
  props: {
    header: { default: "Options" },
    value: { default: "-" },
    options: { default: () => [] },
    addInput: { default: false },
    inputValue: { default: 0 },
    paramRef: {
      default:
        "composantParametreMultiselect Aucune reférence de paramètre transmise",
    },
    inputParamRef: {
      default:
        "composantParametreMultiselect Aucune reférence de paramètre d'input transmise",
    },
    updateParams: {
      default: {
        type: Function,
        default: () => {
          console.error(
            "composantParametreMultiselect : fonction d'update manquante"
          );
        },
      },
    },
  },
};
</script>

<style lang="scss">
.zone-input {
  display: flex;
  & input {
    width: 30%;
  }
}
</style>
